import React, { useState, useEffect } from "react";
import { Menu } from "element-react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { get } from "lodash";

import { USER_ROLES } from "constants/userRoles";

import { superadminItems, adminItems, doctorItems, patientItems } from "./SidebarNavItems";

import HistoryService from "services/history/HistoryService";
import NotificationsService from "services/notifications/NotificationsService";

import styles from "./SidebarNav.module.scss";

function SidebarNav() {
    const { t } = useTranslation();
    const location = useLocation();

    const role = useSelector((state) => state.auth.accountType);

    const { pathname } = location;

    const navItemsMap = {
        [USER_ROLES.superadmin]: superadminItems,
        [USER_ROLES.admin]: adminItems,
        [USER_ROLES.doctor]: doctorItems,
        [USER_ROLES.patient]: patientItems,
    };

    const navItems = navItemsMap[role] || [];

    const getFlatNavItems = (navItems) => {
        const flatNavItems = [];

        navItems.forEach((item) => {
            flatNavItems.push(item);
            if ("child" in item) {
                item.child.forEach((subItem) => {
                    flatNavItems.push(subItem);
                });
            }
        });

        return flatNavItems;
    };

    const onMenuItemClick = (clickedItemIndex) => {
        const flatNavItems = getFlatNavItems(navItems);

        const clickedItem = flatNavItems.find((item) => item.id === clickedItemIndex);

        if (!clickedItem) {
            NotificationsService.throwNotification(
                "error",
                t("UI:SidebarNav.notification.wrongItemPath.title"),
                t("UI:SidebarNav.notification.wrongItemPath.message")
            );

            return;
        }

        const path = get(clickedItem, "path", ""); // TODO: add redirect URL as default (?)

        if (!clickedItem.isTargetBlank) {
            HistoryService.push(`/${path}`);
        }
    };

    const [activeItemIndex, setActiveItemIndex] = useState("d1");

    useEffect(() => {
        const flatNavItems = getFlatNavItems(navItems);

        const activeItem = flatNavItems.find((item) => item.path === pathname.slice(1));

        if (!activeItem) {
            return;
        }

        setActiveItemIndex(activeItem.id);
    }, [pathname]);

    const renderNavListItems = () => {
        return navItems.map((item) => {
            if ("child" in item) {
                return (
                    <Menu.SubMenu
                        key={item.id}
                        index={item.id}
                        className={styles.listItem}
                        title={t(item.captionID)}
                    >
                        {item.child.map((subItem) => {
                            return (
                                <Menu.Item
                                    key={subItem.id}
                                    index={subItem.id}
                                    className={styles.listItem}
                                >
                                    <img
                                        className={styles.icon}
                                        src={subItem.icon}
                                        alt={t("UI:SidebarNav.menuIcon.altText")}
                                    />
                                    {t(subItem.captionID)}
                                </Menu.Item>
                            );
                        })}
                    </Menu.SubMenu>
                );
            } else {
                if (item.id === "separator") {
                    return <div className={styles.separator}></div>;
                } else if (item.isTargetBlank) {
                    return (
                        <a
                            href={item.path}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.externalLinkItem}
                            title={t(item.captionID)}
                        >
                            <img
                                className={styles.icon}
                                src={item.icon}
                                alt={t("UI:SidebarNav.menuIcon.altText")}
                            />
                        </a>
                    );
                } else {
                    return (
                        <Menu.Item
                            key={item.id}
                            index={item.id}
                            className={styles.listItem}
                            title={t(item.captionID)}
                        >
                            <img
                                className={styles.icon}
                                src={item.icon}
                                alt={t("UI:SidebarNav.menuIcon.altText")}
                            />
                        </Menu.Item>
                    );
                }
            }
        });
    };

    return (
        // TODO: fix styles ordering and remove sidebarNav wrapper
        <div className={styles.sidebarNav}>
            <Menu
                defaultActive={activeItemIndex}
                className={styles.list}
                onSelect={(clickedItemIndex) => onMenuItemClick(clickedItemIndex)}
            >
                {renderNavListItems()}
            </Menu>
        </div>
    );
}

export default SidebarNav;
